import React, { useState } from 'react'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import Logo from '../assets/ParGlobal_Logo.jpg'
import PhoneImage from '../assets/Phone_Icon@2x.png'
import EmailImage from '../assets/Email_Icon.png'
const Navigation = () => {
  const [show, setShow] = useState(false)

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to={`/`}>
            <img
              alt="Par Global US"
              src={Logo}
              style={{ height: '100px', width: '100px', objectFit: 'contain' }}
            />{' '}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="justify-content-end flex-grow-1 pe-3 align-items-center">
            <Nav.Link>
              <Link to={`/`}>Home</Link>
            </Nav.Link>
            <NavDropdown
              title="Products"
              id="nav-dropdown"
              show={show}
              onMouseOver={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <NavDropdown.Item eventKey="4.1">
                <Link to={`/products`} state={{ category: 'All Products' }}>
                  All Products
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item eventKey="4.3">
                <Link to={`/products`} state={{ category: 'Geosynthetics' }}>
                  Geosynthetics
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.6">
                <Link to={`/products`} state={{ category: 'Erosion Control' }}>
                  Erosion Control
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">
                <Link to={`/products`} state={{ category: 'Agro Textiles' }}>
                  Agro Textiles
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.4">
                <Link
                  to={`/products`}
                  state={{ category: 'Industrial Textiles' }}
                >
                  Industrial Textiles
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.5">
                <Link
                  to={`/products`}
                  state={{ category: 'Packaging Textiles' }}
                >
                  Packaging Textiles
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.7">
                <Link to={`/products`} state={{ category: 'Accessories' }}>
                  Accessories
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link to={`/about`}>About</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={`/#news`}>News</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to={`/#contact`}>Contact</Link>
            </Nav.Link>
            <Navbar.Text style={{ paddingLeft: '12px' }}>
              <small className="phone-contact">
                <Image src={PhoneImage} className="phone-icon" />
                <Link className="text-gold phone-number" to={`tel:7705447392`}>
                  770-544-7392
                </Link>
              </small>
              <small className="phone-contact">
                <Image src={EmailImage} className="phone-icon" />
                <Link
                  className="text-gold phone-number"
                  to={`mailto:sales@parglobal.us`}
                >
                  sales@parglobal.us
                </Link>
              </small>
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default React.memo(Navigation)
