import React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

import TbpaLogo from '../assets/TBPA_Logo.png'
import IfaiLogo from '../assets/IFAI_Logo.png'
import AtaLogo from '../assets/ATA_Logo.png'
import PeanutLogo from '../assets/peanut.png'
import PhoneImage from '../assets/Phone_Icon@2x.png'
import EmailImage from '../assets/Email_Icon.png'

const Footer = () => (
  <div className="footer-container">
    <Container as="footer">
      <Row>
        <Col md={4}>
          <div className="footer-address">
            <small>Par Global</small>
            <br />
            <small className="phone-contact">
              <Image src={PhoneImage} className="phone-icon" />
              <Link className="text-gold phone-number" to={`tel:7705447392`}>
                770-544-7392
              </Link>
            </small>
            <small className="phone-contact">
              <Image src={EmailImage} className="phone-icon" />
              <Link
                className="text-gold phone-number"
                to={`mailto:sales@parglobal.us`}
              >
                sales@parglobal.us
              </Link>
            </small>
          </div>
        </Col>
        <Col
          md={{ span: 6, offset: 2 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Row>
            <Col>
              <small>Proud Member Of:</small>
              <br />
              <br />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 8, offset: 2 }} sm={{ span: 3, offset: 0 }}>
              <img
                height="50"
                src={TbpaLogo}
                style={{ objectFit: 'contain' }}
              />
            </Col>
            <Col xs={{ span: 8, offset: 2 }} sm={{ span: 3, offset: 0 }}>
              <img src={IfaiLogo} />
            </Col>
            <Col xs={{ span: 8, offset: 2 }} sm={{ span: 3, offset: 0 }}>
              <img src={AtaLogo} />
            </Col>
            <Col xs={{ span: 8, offset: 2 }} sm={{ span: 3, offset: 0 }}>
              <img src={PeanutLogo} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Footer
